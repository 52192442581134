import React, { useState } from "react";
import { Link } from "react-router-dom";
import { axiosWithAuth } from "./utils/axiosWithAuth";

import styled from "styled-components";

const NavDiv = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormDiv = styled.div`
  // border: 1px solid black;
  height: 600px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MidDiv = styled.div`
  border-top: 1px solid black;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonStyle = styled.button`
  padding: 20px;
  border-radius: 5px;
  box-shadow: none;
  font-size: 18px;
  margin-left: 40px;
  background-color: #716595;
  color: white;
`;

const InputDiv = styled.div`
  border: 1px solid black;
  height: 250px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogOutButton = styled.button`
  margin-left: 15px;
`;

const InputStyle = styled.input`
  width: 100%;
  padding: 15px;
  margin-top: 5px;
`;

const FormStyle = styled.form`
  width: 80%;
`;

const SubmitButton = styled.button`
  padding: 10px;
  margin-top: 5px;
  width: 25%;
`;

const SubmitCount = props => {

  var userName = (localStorage.getItem("user"))

  console.log(userName)

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = `${mm}-${dd}-${yyyy}`;

  const [countInfo, setCountInfo] = useState({
    date: today,
    name: "",
    count: ""
  })

  const handleChanges = event => {
    setCountInfo({
      ...countInfo,
      date: today,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = event => {
    event.preventDefault();
    axiosWithAuth().post("/api/meetings/count", countInfo)
      .then(response => {
        console.log(response)
        props.history.push("/thanks")
      })
      .catch(error => {
        console.log(error)
      })
  };

  const logOut = event => {
    localStorage.clear("token")
    props.history.push("/")
  }

  return (
    <div>
      <NavDiv>
        {/* <LogOutButton onClick={logOut}>Log Out</LogOutButton> */}
        <h1>Attendance Count for Soledad English Congregation</h1>
        {
          (userName === "admin")
            ? <div><LogOutButton onClick={logOut}>Log Out</LogOutButton></div>
            : <div></div>
        }
      </NavDiv>
      <MidDiv>
        <FormDiv>
          {
            (userName === "admin")
              ? <h1>Welcome {userName}</h1>
              : <div></div>
    }
          {
            (userName === "admin")
              ? <Link to="/meetings">
                <ButtonStyle>View Meeting Counts</ButtonStyle>
              </Link>
              : <div></div>
          }
          <div>
            <h3>Please submit the FIRST and LAST name of the head of the household and the number of people tied into the meeting</h3>
            <h4>Submitting count for the {today} meeting</h4>
          </div>
          <InputDiv>
            <FormStyle onSubmit={handleSubmit}>
              <InputStyle
                placeholder="FIRST and LAST name"
                type="text"
                name="name"
                value={countInfo.name}
                onChange={handleChanges}
              />
              <br />
              <InputStyle
                placeholder="Number of people listening"
                type="text"
                name="count"
                value={countInfo.count}
                onChange={handleChanges}
              />
              <br />
              <SubmitButton type="submit">Submit</SubmitButton>
            </FormStyle>
          </InputDiv>
        </FormDiv>
      </MidDiv>
    </div>
  )
};

export default SubmitCount;
