import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

const NavDiv = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid black;
`;

const MonthDiv = styled.div`
  border: 1px solid black;
  width: 48%;
`;

const MainContain = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Meetings = (props) => {

  const marchDate = [ 
    {date: "03-22-2020"},
    {date: "03-26-2020"},
    {date: "03-29-2020"}
  ];

  const aprilDate = [
    {date: "04-02-2020"},
    {date: "04-05-2020"},
    {date: "04-09-2020"},
    {date: "04-12-2020"},
    {date: "04-16-2020"},
    {date: "04-19-2020"},
    {date: "04-23-2020"},
    {date: "04-26-2020"},
    {date: "04-30-2020"}
  ];

  return (
    <div>
      <NavDiv>
        <h1>Attendance Count for Soledad English Congregation</h1>
      </NavDiv>
      <div>
        <h3>Please click on the meeting you'd like to view counts for.</h3>
      </div>
      <MainContain>
      <MonthDiv>
        <h1>March 2020</h1>
      {marchDate.map((item) => (
        <div>
          <Link to={`/counts/?date=${item.date}`}>
          <p>{item.date}</p>
          </Link>
        </div>
      ))}
      </MonthDiv>
      <MonthDiv>
        <h1>April 2020</h1>
       {aprilDate.map((item) => (
        <div>
          <Link to={`/counts/?date=${item.date}`}>
          <p>{item.date}</p>
          </Link>
        </div>
      ))}
      </MonthDiv>
      </MainContain>
    </div>
  )
};

export default Meetings;