import React, { useState, useEffect } from "react";
import { axiosWithAuth } from "./utils/axiosWithAuth";
import queryString from "query-string";
import styled from "styled-components";

const NavDiv = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid black;
`;

const TotalDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const MainDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const NameDiv = styled.div`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 300px;
`;

const Counts = (props) => {

  const { date } = queryString.parse(props.location.search);

  const [count, setCount] = useState([]);

  //API Call For Dates
  useEffect(() => {
    axiosWithAuth().get(`/api/meetings/count/${date}`)
      .then(response => {
        setCount(response.data)
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [date])

  const totalCount = count.reduce((previousScore, currentScore, index) => previousScore + currentScore.count, 0)

  return (
    <div>
      <NavDiv>
        <h1>Attendance Count for Soledad English Congregation</h1>
      </NavDiv>
      <TotalDiv>
        <h1>Total Count for {date} - {totalCount}</h1>
      </TotalDiv>
      <MainDiv>
        {count.map((item) => (
          <NameDiv>
            <p>Name: {item.name}</p>
            <p>Count: {item.count}</p>
          </NameDiv>
        ))}
      </MainDiv>
    </div>
  )
};

export default Counts;