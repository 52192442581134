import React from "react";
import styled from "styled-components";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogOut = styled.button`
  border: 1px solid black;
  padding: 20px;
  border-radius: 5px
`;

const Thanks = (props) => {

  const logOut = event => {
    localStorage.clear("token")
    props.history.push("/")
  };

  return (
    <MainDiv>
      <h1>Thank you for submitting your count!</h1>
        <h2>Please Logout!</h2>
        <LogOut onClick={logOut}>LOG OUT</LogOut>
    </MainDiv>
  )
};

export default Thanks;