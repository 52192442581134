import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Private from "./Components/PrivateRouter";
import Login from "./Components/Login";
import Counts from "./Components/Counts";
import Meetings from "./Components/Meetings";
import Thanks from "./Components/Thanks";
import SubmitCount from './Components/SubmitCount';


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Private path="/meetings" component={Meetings} />
          <Private path="/counts" component={Counts}/>
          <Private path="/count-form" component={SubmitCount} />
          <Route exact path="/" component={Login} />
          <Route path="/thanks" component={Thanks} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
