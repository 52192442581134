import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { axiosWithAuth } from "./utils/axiosWithAuth";

const NavContain = styled.div`
  text-align: center;
`;

const MainDiv = styled.div`
  // border: 1px solid black;
`;

const FormDiv = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 400px;
`;

const FormContain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const Login = props => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  })

  const handleChange = event => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    })
  };

  const handleSubmit = event => {
    event.preventDefault();
    axiosWithAuth().post("/api/auth/login", credentials)
    // axios.post("http://localhost:5555/api/auth/login", credentials)
    .then(res => {
      localStorage.setItem("token", res.data.token)
      localStorage.setItem("user", res.data.username)
      console.log(res)
      props.history.push("/count-form")
      setCredentials({
        username: "",
        password: ""
      })
    })
    .catch(error => {
      console.log(error)
    })
  };

  return (
    <MainDiv>
      <NavContain>
      <h3>Welcome to Attendance Count for the Soledad English Congregation. Please login to continue!</h3>
      </NavContain>
      <FormContain>
      <FormDiv>
      <form onSubmit={handleSubmit}>
        <h3>Please Login</h3>
        <label>Username: </label>
        <br />
        <input
        id="username"
        type="text"
        name="username"
        value={credentials.username}
        onChange={handleChange}
        />
        <br/>
        <label>Password: </label>
        <br />
        <input
        id="password"
        type="password"
        name="password"
        value={credentials.password}
        onChange={handleChange}
        />
        <br />
        <button type="submit">Login</button>
      </form>

      </FormDiv>
      </FormContain>
    </MainDiv>
  )
};

export default Login;